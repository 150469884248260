import React from "react"

import "../styles/styles.scss"

const Button = ({ children, type, onClick }) => {
  return (
    <>
      <div className="button-global" type={type}>
        {children}
      </div>
    </>
  )
}

export default Button
