import { StaticImage } from "gatsby-plugin-image"
import React from "react"

const Team = () => {
  return (
    <div className="team-container">
      <div className="card-container">
        <div className="image">
          <StaticImage
            className="drop-shadow"
            height={240}
            src="../images/crash-bandicoot(12).jpg"
            alt="team image"
          />{" "}
        </div>
        <div className="caption">
          <h1 className="heading">
            Peter <br /> Kostogiannis
          </h1>
          <p className="subheading">Founder</p>
          <a
            className="twitter-links"
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/p_blabs"
          >
            <p className="subheading">Twitter</p>
          </a>
        </div>
      </div>
      <div className="card-container">
        <div className="image">
          <StaticImage
            className="drop-shadow"
            height={240}
            src="../images/crash-bandicoot(11).jpg"
            alt="team image"
          />{" "}
        </div>
        <div className="caption">
          <h1 className="heading">
            Ethan <br /> Isaacs
          </h1>
          <p className="subheading">Co-founder</p>
          <a
            className="twitter-links"
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/EthanIsaacs"
          >
            <p className="subheading">Twitter</p>
          </a>
        </div>
      </div>
      <div className="card-container">
        <div className="image">
          <StaticImage
            className="drop-shadow"
            height={240}
            src="../images/crash-bandicoot(9).jpg"
            alt="team image"
          />{" "}
        </div>
        <div className="caption">
          <h1 className="heading">
            Olly <br /> Evans
          </h1>
          <p className="subheading">Creative Director</p>
          <a
            className="twitter-links"
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/ollydevans"
          >
            <p className="subheading">Twitter</p>
          </a>
        </div>
      </div>
      <div className="card-container">
        <div className="image">
          <StaticImage
            className="drop-shadow"
            height={240}
            src="../images/crash-bandicoot(13).jpg"
            alt="team image"
          />{" "}
        </div>
        <div className="caption">
          <h1 className="heading">
            Kosta
            <br /> Konstantopoulos
          </h1>
          <p className="subheading">Community Manager</p>
          <a
            className="twitter-links"
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/leonidasleo14"
          >
            <p className="subheading">Twitter</p>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Team
