import React from "react"

import Button from "./button"

// import "../styles/variables.scss"
import "../styles/styles.scss"
import { StaticImage } from "gatsby-plugin-image"

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="inner-banner">
        <div className="heading">
          <h1>Backdrop Ɪ Collection</h1>
        </div>
        <div className="subheading">
          <p>
            777 Unique Backdrops,
            <br /> brewed in the lab...
          </p>
        </div>
        <div className="button">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://discord.gg/zwPhTMCkPy"
          >
            <Button type="button">
              JOIN THE LAB
              <StaticImage
                className="discord-button-img"
                src="../images/discord2.png"
                alt="Discord logo"
                width={40}
              />
            </Button>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Banner
