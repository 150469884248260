import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/styles.scss"

// Components
import Header from "../components/header"
import Seo from "../components/seo"
import Banner from "../components/banner"
import About from "../components/about"
import Gallery from "../components/gallery"
import Roadmap from "../components/roadmap"
import Team from "../components/team"
import Footer from "../components/footer"
import RoadmapReversed from "../components/roadmapReversed"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Seo
        title="Welcome"
        author="Olly Evans"
        siteTitle={data.site.siteMetadata?.title || `Home`}
      />
      <Header />
      <Banner />
      <About />
      <Gallery />
      <div className="roadmap-title">
        <h1>The Roadmap</h1>
      </div>
      <Roadmap
        title="The Roadmap"
        heading="Concept & Early Dev"
        line1="- Develop Backdrop Ɪ NFT project concept"
        line2="- Generate Backdrop Ɪ art in 24x24 format"
        number="01"
      />
      <Roadmap
        heading="Community Preparation"
        line1="- Dev BackdropLabs website"
        line2="- Launch best discord server in the game"
        line3="- Launch public roadmap"
        number="02"
      />
      <RoadmapReversed
        heading="Public launch"
        line1="- Release Backdrop Ɪ sneak peaks"
        line2="- Twitter incentives/raids"
        line3="- Discord invite incentives"
        line4="- Connect with community"
        number="03"
      />
      <RoadmapReversed
        heading="Minting sub-phases"
        line1="- 6 Minting sub-phases, see discord for more"
        number="04"
      />
      <Roadmap
        heading="Marketing"
        line1="- Verify BackdropLabs with appropriate streams"
        line2="- Development of earnable easter egg NFTs"
        line3="- 'Backsets' released after 100% Sold Out"
        line4="- 31st October Special Halloween Backdrop giveaway"
        number="05"
      />
      <Roadmap
        heading="Backdrop ꞮꞮ"
        line1="- Development of Backdrop ꞮꞮ will begin after Backdrop Ɪ"
        line2="- Collaboration between top NFT collections and Backdrop"
        line3="- Conceptualisation and development of art"
        number="06"
      />
      <Team />
      <Footer />
    </>
  )
}

export default IndexPage
