import React from "react"

const roadmapReversed = ({ heading, line1, line2, line3, line4, number }) => {
  return (
    <div className="roadmapReversed-container">
      <div className="text">
        <div className="heading">{heading}</div>
        <div className="copy">
          {line1}
          <br />
          {line2}
          <br />
          {line3}
          <br />
          {line4}
        </div>
      </div>
      <div className="image-container">
        <div className="stage-number">
          <p>{number}</p>
        </div>
      </div>
    </div>
  )
}

export default roadmapReversed
