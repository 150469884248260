import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const About = () => {
  return (
    <div className="about-container">
      <div className="flex-row">
        <div className="text">
          <div className="heading">
            <h1>What is a backdrop?</h1>
          </div>
          <div className="subheading">
            <p>
              Backdrops are unique backgrounds designed specifically for NFT
              collections on the Ethereum blockchain. <br />
              <br />
              Our first collection, Backdrop Ɪ, consists of <em>777</em> unique
              Backdrops for the coveted CryptoPunk collection, the <em>777</em>{" "}
              Backdrops capture the essence of CryptoPunks perfectly by
              utilising similar themes, traits and identical 24x24 pixel format.
              Backdrop Ɪ also doubles as a 'genesis' membership for the future
              of the Backdrop ecosystem.
            </p>
          </div>
        </div>
        <div className="image">
          <StaticImage
            height={240}
            src="../images/Combined.png"
            alt="Combined backdrop and cryptopunk"
          />
        </div>
      </div>
    </div>
  )
}

export default About
