import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import "../styles/styles.scss"
import downloadFile from "../static/Terms-Conditions-BackdropLabs.pdf"

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="titles">
        <div className="heading">
          <h1>Backdrop Labs</h1>
        </div>
        <div className="subheading">
          <h1>Backdrop Ɪ Collection</h1>
        </div>
      </div>
      <div className="second">
        <div className="socials">
          <a
            rel="noreferrer"
            target="_blank"
            href="https://discord.gg/zwPhTMCkPy"
          >
            <StaticImage
              height={50}
              src="../images/discord.png"
              alt="Discord logo"
            />
          </a>
          <Link className="invalid-click" to="/">
            <StaticImage
              height={50}
              src="../images/opensea.png"
              alt="Opensea logo"
            />
          </Link>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://twitter.com/BackdropLabs"
          >
            <StaticImage
              height={50}
              src="../images/twitter.png"
              alt="Twitter logo"
            />
          </a>
          <a href="mailto:support@backdroplabs.io?subject=Support Inquiry">
            <StaticImage
              src="../images/email.png"
              height={50}
              alt="Email logo"
            />
          </a>
        </div>
        <div className="copy">
          777 Unique Backdrops,
          <br /> brewed in the lab...
        </div>
      </div>
      <hr />
      <div className="legal">
        <div className="copyright">
          <p>© 2021 Backdrop Labs. All rights reserved.</p>
        </div>
        <div className="tandc">
          <a rel="noreferrer" target="_blank" href={downloadFile}>
            Terms & Conditions
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
