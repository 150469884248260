import React from "react"

import alien from "../images/alien.gif"
import bandana from "../images/bandana.gif"
import cap from "../images/cap.gif"
import clown from "../images/clown.gif"
import criminal from "../images/criminal.gif"
import fanhat from "../images/fanhat.gif"
import girl from "../images/girl.gif"
import hood from "../images/hood.gif"
import monkey from "../images/monkey.gif"
import pilot from "../images/pilot.gif"
import smoker from "../images/smoker.gif"
import zombie from "../images/zombie.gif"

const Gallery = () => {
  return (
    <div className="gallery-container">
      <div className="grid">
        <img src={alien} height={240} alt="Backdrop gif" />
        <img src={bandana} height={240} alt="Backdrop gif" />
        <img src={cap} height={240} alt="Backdrop gif" />
        <img src={clown} height={240} alt="Backdrop gif" />
        <img src={criminal} height={240} alt="Backdrop gif" />
        <img src={fanhat} height={240} alt="Backdrop gif" />
        <img src={girl} height={240} alt="Backdrop gif" />
        <img src={hood} height={240} alt="Backdrop gif" />
        <img src={monkey} height={240} alt="Backdrop gif" />
        <img src={pilot} height={240} alt="Backdrop gif" />
        <img src={smoker} height={240} alt="Backdrop gif" />
        <img src={zombie} height={240} alt="Backdrop gif" />
      </div>
    </div>
  )
}
export default Gallery
