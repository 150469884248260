import * as React from "react"
import { Link } from "gatsby"
// import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import "../styles/styles.scss"

const Header = () => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     flower: file(relativePath: { eq: "flower-mouth.png" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 300) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }

  return (
    <header>
      <div className="header-container">
        {/* <StaticImage
          maxHeight={100}
          className="background-image"
          src="../images/pixel.png"
          alt="background image"
          layout="fixed"
        /> */}
        <div className="parallax"></div>
        <nav className="inner-header">
          <div className="logo">
            <Link className="logo-text" to="/">
              Backdrop Labs
            </Link>
          </div>
          <div className="socials">
            <a
              rel="noreferrer"
              target="_blank"
              href="https://discord.gg/zwPhTMCkPy"
            >
              <StaticImage
                height={50}
                src="../images/discord.png"
                alt="Discord logo"
              />
            </a>
            <Link className="invalid-click" to="/">
              <StaticImage
                height={50}
                src="../images/opensea.png"
                alt="Opensea logo"
              />
            </Link>
            <a
              rel="noreferrer"
              target="_blank"
              href="https://twitter.com/BackdropLabs"
            >
              <StaticImage
                height={50}
                src="../images/twitter.png"
                alt="Twitter logo"
              />
            </a>
          </div>
        </nav>
      </div>
    </header>
  )
}
export default Header
